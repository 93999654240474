// Libraries

import React, { Component } from "react"
import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"
import { graphql } from "gatsby"
import { get } from "lodash"
import { navigate } from "gatsby"

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"

// Styles

const Page = styled.div``

const CookiePolicyContentWrapper = styled.div``

class CookiePolicyPage extends Component {
  componentDidMount() {
    navigate("/404")
  }

  render() {
    const cookiePolicyPage = this.props.data.allWordpressPage.nodes[0]

    const pageLayoutProps = {
      ...this.props,
      title: get(cookiePolicyPage, "title"),
      subTitle: get(cookiePolicyPage, "custom.cookie_policy_sub_title"),
      image: get(cookiePolicyPage, "custom.cookie_images"),
      showDividerInResponsive: true,
      hideImageInResponsive: false,
      headerClassName: "cookie-template-subtitle",
      responsiveDividerYOffset: 243,
      showBackButton: true,
    }

    let cookieContent = get(cookiePolicyPage, "custom.cookie_content")

    //create a custom subtitle and content structure. This will have different styling from other pages sub title
    const CONTENT_DETAILS = (
      <CookiePolicyContentWrapper className="cookie-template-content ipad">
        {cookieContent ? ReactHtmlParser(cookieContent) : ""}
      </CookiePolicyContentWrapper>
    )

    return (
      <Page>
        <SEO title={pageLayoutProps.title} />
        <PageLayout {...pageLayoutProps}>{CONTENT_DETAILS}</PageLayout>
      </Page>
    )
  }
}

export default CookiePolicyPage

export const notFound = graphql`
  query cookiePolicy {
    allWordpressPage(filter: { slug: { eq: "cookie-policy" } }) {
      nodes {
        title
        custom {
          cookie_policy_sub_title
          cookie_content
          cookie_images {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`
